import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-modules",
  "date": "2015-09-06",
  "title": "ES6 MODULES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "A module is a self-contained unit of code, which is usually stored in a file. In ES6, modules get language-level support for component definition. Modules export values, which can then be imported by other modules."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`There are two popular standards for JavaScript module loaders.`}</p>
    <p><strong parentName="p">{`CommonJS:`}</strong>{` Used heavily in `}<inlineCode parentName="p">{`Node.js`}</inlineCode></p>
    <ul>
      <li parentName="ul">{`Syntactically compact`}</li>
      <li parentName="ul">{`Loads synchronously`}</li>
      <li parentName="ul">{`Used by the server`}</li>
    </ul>
    <p><strong parentName="p">{`AMD:`}</strong>{` Used by `}<inlineCode parentName="p">{`RequireJS`}</inlineCode></p>
    <ul>
      <li parentName="ul">{`Syntactically complex. Enables AMD to work without compilation.`}</li>
      <li parentName="ul">{`Loads asynchronously`}</li>
      <li parentName="ul">{`Used by the browser`}</li>
    </ul>
    <Message type="info" title="Import & Export" content="You have to use the `export` keyword to explicitly make it available. And use `import` keyword to use the module." mdxType="Message" />
    <p>{`A `}<inlineCode parentName="p">{`module loader`}</inlineCode>{` provides the ability to dynamically load modules, and also keeps track of all loaded modules in a module registry.`}</p>
    <p>{`Typically, in production, the module registry would be populated by an initial compiled bundle of modules. Later in the page state, it may become necessary to dynamically load a new module. This module can then share dependencies with the initial page bundle without having to reload any dependencies.`}</p>
    <Message type="info" title="Custom Module Names" content="Custom module names can be written `./local-module` to load relative to their parent module name. **..** syntax is also supported allowing easily portable modules." mdxType="Message" />
    <h2>{`ES6 Modules`}</h2>
    <h3>{`Exporting`}</h3>
    <p>{`Syntax of ES6 modules was suppose to be a format that both users of CommonJS and of AMD are happy with. But its most similar to the `}<inlineCode parentName="p">{`exports.method = function() {}`}</inlineCode>{` pattern in `}<inlineCode parentName="p">{`NodeJS`}</inlineCode>{` of creating multiple named exports.`}</p>
    <p>{`CommonJS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`exports.someMethod = function() {
    ...
};

exports.another = {};
`}</code></pre>
    <p>{`In ES6, this will be written as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export function someMethod() {
    ...
}

export var another = {};
`}</code></pre>
    <Message type="info" title="" content="Notice that the name of the function, class or variable gets used as the export name." mdxType="Message" />
    <h3>{`Importing`}</h3>
    <p>{`When importing, we import any exports we need by name, and can also choose to rename them:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { someMethod, another as newName } from './exporter';

someMethod();
typeof newName == 'object';
`}</code></pre>
    <h3>{`Default Import and Export`}</h3>
    <p>{`Sometimes one doesn't want to write an import name at all. For this we can use the default export:`}</p>
    <p>{`To export:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export default function foo() {
    console.log('foo');
}
`}</code></pre>
    <p>{`To import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import customName from './export-default';

customName(); // -> 'foo'
`}</code></pre>
    <h3>{`Supported Import Syntax`}</h3>
    <p>{`There are a few other variations of module syntax, the full list of supported `}<inlineCode parentName="p">{`import`}</inlineCode>{` statements is listed below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* import a module without any import bindings */
import 'jquery';
/* import the default export of a module */
import $ from 'jquery';
/* import a named export of a module */
import { $ } from 'jquery';
/* import a named export to a different name */
import { $ as jQuery } from 'jquery';
/* import an entire module instance object */
import * as crypto from 'crypto';
`}</code></pre>
    <h3>{`Supported Export Syntax`}</h3>
    <p>{`A full list of supported `}<inlineCode parentName="p">{`export`}</inlineCode>{` statements list:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* export a named variable */
export var x = 42;
/* export a named function */
export function foo() {};

/* export the default export */
export default 42;
/* export the default export as a function */
export default function foo() {};

/* export an existing variable */
export { encrypt };
/* export a variable as a new name */
export { decrypt as dec };
/* export an export from another module */
export { encrypt as en } from 'crypto';
/* export all exports from another module */
export * from 'crypto';
`}</code></pre>
    <p>{`Note that any valid declaration can be exported. In ES6, this includes class (as in the example above), `}<inlineCode parentName="p">{`const`}</inlineCode>{`, and `}<inlineCode parentName="p">{`let`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      